import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { fundraiserService } from '../services/api';
import { useAdmin } from '../contexts/AdminContext';
import { getImageSrc } from '../utils/imageHandler';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogDescription,
} from "../components/ui/dialog";

function BrowseFundraisers() {
  const [fundraisers, setFundraisers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [categoryFilter, setCategoryFilter] = useState('all');
  const [sortBy, setSortBy] = useState('newest');
  const [showEnded, setShowEnded] = useState(false);
  
  const [showEndDateDialog, setShowEndDateDialog] = useState(false);
  const [endedFundraiser, setEndedFundraiser] = useState(null);

  const { isAdmin } = useAdmin();

  const categories = [
    'all',
    'general',
    'emergency',
    'education',
    'medical',
    'memorial',
    'nonprofit'
  ];

  const isEnded = (endDate) => {
    return new Date(endDate) < new Date();
  };

  const handleEndedClick = (fundraiser) => {
    if (fundraiser.currentAmount > 0) {
      setEndedFundraiser(fundraiser);
      setShowEndDateDialog(true);
    }
  };

  // useEffect(() => {
  //   const fetchFundraisers = async () => {
  //     try {
  //       setLoading(true);
  //       const response = await fundraiserService.getAll();
  //       const fundraisersData = Array.isArray(response) ? response : [];
  //       console.log('Fetched fundraisers:', fundraisersData);
  //       setFundraisers(fundraisersData);
  //     } catch (error) {
  //       console.error('Error fetching fundraisers:', error);
  //       setFundraisers([]);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchFundraisers();
  // }, [categoryFilter, sortBy]);

  useEffect(() => {
    const fetchFundraisers = async () => {
      try {
        setLoading(true);
        const response = await fundraiserService.getAll();
        const fundraisersData = Array.isArray(response) ? response : [];
        console.log('Fetched fundraisers:', fundraisersData);
        setFundraisers(fundraisersData);
      } catch (error) {
        console.error('Error fetching fundraisers:', error);
        setFundraisers([]);
      } finally {
        setLoading(false);
      }
    };
  
    fetchFundraisers();
  // Remove the dependency array items that cause rerenders
  }, []); // Now only runs on mount

  const filteredFundraisers = fundraisers
    .filter(fundraiser => {
      const matchesCategory = categoryFilter === 'all' || fundraiser.category === categoryFilter;
      const matchesSearch = searchTerm === '' || 
        fundraiser.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        fundraiser.description?.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesEndedFilter = showEnded ? isEnded(fundraiser.endDate) : !isEnded(fundraiser.endDate);
      
      return matchesCategory && matchesSearch && matchesEndedFilter;
    })
    .sort((a, b) => {
      if (sortBy === 'newest') return new Date(b.createdAt) - new Date(a.createdAt);
      if (sortBy === 'oldest') return new Date(a.createdAt) - new Date(b.createdAt);
      if (sortBy === 'goal-high') return b.goal - a.goal;
      if (sortBy === 'goal-low') return a.goal - b.goal;
      return 0;
    });

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-[50vh]">
        <div className="text-xl">Loading fundraisers...</div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <div className="mb-8 space-y-4">
        <div className="flex flex-col md:flex-row gap-4">
          <Link
            to="/fundraisers/create" 
            className="px-6 py-3 rounded-full bg-[#28923C] text-black hover:bg-blue-600 transition-colors"
          >
            Start Fundraising
          </Link>
          
          <input
            type="text"
            placeholder="Search fundraisers..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="bg-white text-black flex-1 p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />

          <select
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)} 
            style={{color: 'black'}}
            className="bg-white p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500 px-3"
          >
            <option value="newest">Newest</option>
            <option value="oldest">Oldest</option>
            <option value="goal-high">Highest</option>
            <option value="goal-low">Lowest</option>
          </select>

          <button
            onClick={() => setShowEnded(!showEnded)}
            className={`px-4 py-2 rounded transition-colors ${
              showEnded 
                ? 'bg-yellow-500 hover:bg-yellow-600' 
                : 'bg-gray-200 hover:bg-gray-300'
            } text-black`}
          >
            {showEnded ? 'Show Past Fundraisers' : 'Show Past Fundaisers'}
          </button>
        </div>
      </div>

      {filteredFundraisers.length === 0 ? (
        <div className="text-center py-12">
          <p className="text-gray-600 text-lg">No fundraisers found</p>
          <Link 
            to="/fundraisers/create" 
            className="mt-4 inline-block bg-[#28923C] text-black px-6 py-3 rounded-full hover:bg-blue-600 transition-colors"
          >
            Create a Fundraiser
          </Link>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredFundraisers.map((fundraiser) => (
            <div key={fundraiser._id} className="bg-white rounded-lg shadow-md overflow-hidden border-2 border-white relative min-h-[300px]">
              <div className="p-6 flex flex-col h-full">
                <div className="flex items-start justify-between">
                  <div>
                    <h2 className="text-xl font-semibold mb-2 text-black">{fundraiser.title}</h2>
                    
                    {fundraiser.images && fundraiser.images.length > 0 && (
                      <img
                        src={getImageSrc(fundraiser.images[0])}
                        alt={`${fundraiser.title} featured`}
                        className="w-full h-48 object-cover rounded mb-4"
                      />
                    )}

                    <p className="text-sm mb-2">
                      <span className='text-black'>
                        Created {new Date(fundraiser.createdAt).toLocaleDateString()} by {fundraiser.creator?.username || 'Anonymous'}
                      </span>
                    </p>
                  </div>
                </div>
                
                <p className="text-black mb-4 line-clamp-3 flex-grow">{fundraiser.description}</p>
                
                <div className="space-y-2">
                  <div className="text-center text-black text-sm -mt-2 font-bold italic">
                    {Math.round((fundraiser.currentAmount / fundraiser.goal) * 100) || 0}% Funded
                  </div>
                  <div className="w-3/4 mx-auto">
                    <div className="h-4 bg-white border border-white rounded-full overflow-hidden">
                      <div
                        className="h-full bg-green-500 border-r border-white rounded-full min-w-[2px]"
                        style={{
                          width: `${Math.min((fundraiser.currentAmount / fundraiser.goal) * 100, 100)}%`
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex justify-between text-sm mt-4">
                    <span className="text-black"><span className="font-bold">Raised:</span> ${(fundraiser.currentAmount || 0).toLocaleString()}</span>
                    <span className="text-black"><span className="font-bold">Goal:</span> ${(fundraiser.goal || 0).toLocaleString()}</span>
                  </div>
                </div>
                
                <div className="mt-8 flex justify-end gap-2">
                  {isEnded(fundraiser.endDate) && (
                    <button
                      onClick={() => handleEndedClick(fundraiser)}
                      className="bg-yellow-500 text-black px-4 py-2 rounded font-semibold hover:bg-yellow-600 transition-colors"
                    >
                      Ended
                    </button>
                  )}
                  <Link
                    to={`/fundraisers/${fundraiser._id}`}
                    className="inline-block bg-blue-500 text-black px-4 py-2 rounded hover:bg-blue-700 transition-colors"
                  >
                    View Details
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      <Dialog open={showEndDateDialog} onOpenChange={setShowEndDateDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Fundraiser Ended</DialogTitle>
            <DialogDescription>
              {endedFundraiser && (
                <div className="mt-4 space-y-4">
                  <p>Fundraiser "{endedFundraiser.title}" has ended.</p>
                  <p>
                    Contact the admin at{' '}
                    <a 
                      href="mailto:info@hustlefundraise.com"
                      className="text-blue-400 hover:text-blue-300"
                    >
                      info@hustlefundraise.com
                    </a>
                    {' '}for payout arrangements.
                  </p>
                  <p>Thank you for using our platform!</p>
                </div>
              )}
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <button
              onClick={() => setShowEndDateDialog(false)}
              className="px-4 py-2 bg-blue-500 text-black rounded hover:bg-blue-600 transition-colors"
            >
              Close
            </button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default BrowseFundraisers;