import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { fundraiserService, authService } from '../services/api';
import { useAdmin } from '../contexts/AdminContext';
import { Card, CardContent, CardHeader, CardTitle } from '../components/ui/card';
import { Badge } from '../components/ui/badge';
import { Button } from '../components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../components/ui/dialog';


const AdminDashboard = () => {
  const navigate = useNavigate();
  const { isAdmin, setIsAdmin } = useAdmin(); // Ensure `setIsAdmin` can be called here
  const [pendingFundraisers, setPendingFundraisers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedFundraiser, setSelectedFundraiser] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [approvalAction, setApprovalAction] = useState(null);


  useEffect(() => {
    const authenticateAndFetchData = async () => {
      try {
        // Trigger HubSpot login if not authenticated
        if (!isAdmin) {
          authService.login();
          const { isAuthenticated, user } = await authService.checkAuth();
          if (isAuthenticated && user.email === 'info.hustlefundraise.com') {
            setIsAdmin(true); // Set isAdmin to true if HubSpot authentication is successful
          } else {
        
            // navigate('/login');
            alert('why')
            return;
          }
        }

        // Fetch pending fundraisers if authenticated as admin
        setLoading(true);
        const response = await fundraiserService.getPendingFundraisers();
        setPendingFundraisers(response);
      } catch (err) {
        setError('Failed to verify admin or fetch data');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    authenticateAndFetchData(); // Trigger authentication and fetch on component mount
  }, [isAdmin, navigate, setIsAdmin]);

  const openConfirmDialog = (fundraiser, isApproval) => {
    setSelectedFundraiser(fundraiser);
    setApprovalAction(isApproval);
    setDialogOpen(true);
  };

  const handleApproval = async () => {
    try {
      await fundraiserService.updateApprovalStatus(selectedFundraiser._id, approvalAction);
      setPendingFundraisers(prev => 
        prev.filter(fundraiser => fundraiser._id !== selectedFundraiser._id)
      );
      setDialogOpen(false);
      setSelectedFundraiser(null);
    } catch (err) {
      setError('Failed to update approval status');
      console.error(err);
    }
  };

  if (loading) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>;
  }

  return (
    <div className="max-w-7xl mx-auto p-6">
      <Card className="mb-6">
        <CardHeader>
          <CardTitle>Admin Dashboard</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-1 gap-4">
            <div className="flex justify-between items-center">
              <h2 className="text-xl font-semibold">Pending Fundraisers</h2>
              <Badge variant="secondary">
                {pendingFundraisers.length} Pending
              </Badge>
            </div>
          </div>
        </CardContent>
      </Card>

      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
          {error}
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {pendingFundraisers.map(fundraiser => (
          <Card key={fundraiser._id} className="relative">
            <CardHeader>
              <CardTitle>{fundraiser.title}</CardTitle>
              <Badge className="absolute top-4 right-4" variant="outline">
                Pending
              </Badge>
            </CardHeader>
            <CardContent>
              <p className="mb-4 text-sm">{fundraiser.description}</p>
              <div className="space-y-2">
                <div className="flex justify-between text-sm">
                  <span>Goal:</span>
                  <span className="font-medium">
                    ${fundraiser.goal.toLocaleString()}
                  </span>
                </div>
                <div className="flex justify-between text-sm">
                  <span>Created by:</span>
                  <span>{fundraiser.creator?.username}</span>
                </div>
                <div className="flex justify-between text-sm">
                  <span>Date:</span>
                  <span>
                    {new Date(fundraiser.createdAt).toLocaleDateString()}
                  </span>
                </div>
              </div>
              <div className="mt-6 space-x-4 flex justify-end">
                <Button 
                  variant="destructive"
                  onClick={() => openConfirmDialog(fundraiser, false)}
                >
                  Reject
                </Button>
                <Button 
                  variant="default"
                  onClick={() => openConfirmDialog(fundraiser, true)}
                >
                  Approve
                </Button>
              </div>
            </CardContent>
          </Card>
        ))}
      </div>

      <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              {approvalAction ? 'Approve Fundraiser' : 'Reject Fundraiser'}
            </DialogTitle>
            <DialogDescription>
              {approvalAction 
                ? 'Are you sure you want to approve this fundraiser? This will make it visible to all users.'
                : 'Are you sure you want to reject this fundraiser? The creator will be notified.'}
            </DialogDescription>
          </DialogHeader>

          {selectedFundraiser && (
            <div className="py-4">
              <h3 className="font-medium mb-2">{selectedFundraiser.title}</h3>
              <p className="text-sm text-gray-500 mb-2">
                Created by: {selectedFundraiser.creator?.username}
              </p>
              <p className="text-sm text-gray-500">
                Goal: ${selectedFundraiser.goal.toLocaleString()}
              </p>
            </div>
          )}

          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => setDialogOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant={approvalAction ? 'default' : 'destructive'}
              onClick={handleApproval}
            >
              {approvalAction ? 'Approve' : 'Reject'}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AdminDashboard;
