import { FaEnvelope, FaPhone } from 'react-icons/fa';

function Contact() {
  return (
    <div className="max-w-4xl mx-auto px-4 py-12">
      <h1 className="text-3xl font-bold text-black mb-8 text-center">Contact Us</h1>
      
      <div className="bg-white rounded-lg shadow-lg p-8">
        <div className="space-y-8">
          {/* Contact Info */}
          <div className="space-y-4">
            <h2 className="text-2xl font-semibold text-black mb-4">Get in Touch</h2>
            <p className="text-black mb-6">
              Have questions about starting a fundraiser or need support? We're here to help!
            </p>
            
            <div className="flex items-center space-x-3 text-black">
              <FaEnvelope className="text-[#28923C] h-5 w-5" />
              <a 
                href="mailto:info@hustlefundraise.com" 
                className="hover:text-[#28923C] transition-colors"
              >
                info@hustlefundraise.com
              </a>
            </div>
            
            {/* <div className="flex items-center space-x-3 text-black">
              <FaPhone className="text-[#28923C] h-5 w-5" />
              <span>(555) 123-4567</span>
            </div> */}

          </div>

          {/* FAQ Section */}
          {/* <div className="space-y-4">
            <h2 className="text-2xl font-semibold text-black">Frequently Asked Questions</h2>
            <div className="space-y-4">
              <div>
                <h3 className="font-bold text-black mb-2">How do I start a fundraiser?</h3>
                <p className="text-black">
                  Click the "Start Fundraising" button and follow our simple setup process. We'll guide you through each step.
                </p>
              </div>
              
              <div>
                <h3 className="font-bold text-black mb-2">When do I receive donations?</h3>
                <p className="text-black">
                  Funds are available for withdrawal once your fundraiser reaches its end date or upon request for active fundraisers.
                </p>
              </div>
              
              <div>
                <h3 className="font-bold text-black mb-2">Is there a platform fee?</h3>
                <p className="text-black">
                  We charge a small processing fee to cover transaction costs. Contact us for detailed information.
                </p>
              </div>
            </div>
          </div> */}

          {/* Support Hours */}
          {/* <div className="border-t pt-6">
            <h3 className="font-bold text-black mb-2">Support Hours</h3>
            <p className="text-black">
              Monday - Friday: 9:00 AM - 5:00 PM EST<br />
              Weekend support available for urgent matters
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Contact;