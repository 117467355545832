import * as React from "react"
import { cn } from "../../lib/utils"

const buttonVariants = {
  default: "bg-blue-500 text-black hover:bg-blue-600",
  destructive: "bg-red-500 text-black hover:bg-red-600",
  outline: "border border-white text-black hover:bg-gray-800",
  secondary: "bg-gray-800 text-black hover:bg-gray-700",
  ghost: "text-black hover:bg-gray-800",
  link: "text-blue-500 underline-offset-4 hover:underline"
}

const Button = React.forwardRef(({ 
  className, 
  variant = "default", 
  size = "default",
  ...props 
}, ref) => {
  return (
    <button
      className={cn(
        "inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
        "px-4 py-2", // Default size
        buttonVariants[variant],
        className
      )}
      ref={ref}
      {...props}
    />
  )
})
Button.displayName = "Button"

export { Button, buttonVariants }