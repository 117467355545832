import React, { useState, useEffect, useRef } from 'react';
import { userService, fundraiserService } from '../services/api';
import { Card, CardHeader, CardTitle, CardContent } from "../components/ui/card";
import { Avatar, AvatarFallback, AvatarImage } from "../components/ui/avatar";
import { Input } from "../components/ui/input";
import { Button } from "../components/ui/button";
import { ChevronDown } from 'lucide-react';
import { Link } from 'react-router-dom';
import { getAvatarImageSrc } from '../utils/imageHandler';


const UserProfile = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [joinCode, setJoinCode] = useState('');
  const [showPastFundraisers, setShowPastFundraisers] = useState(false);
  const fileInputRef = useRef(null);


  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const data = await userService.getProfile();
        console.log('Received user data:', {
          hasAvatarImage: !!data.avatarImage,
          avatarImageData: data.avatarImage ? {
            hasData: !!data.avatarImage.data,
            dataType: typeof data.avatarImage.data,
            contentType: data.avatarImage.contentType
          } : null
        });
        setUserData(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      console.log('No file selected');
      return;
    }
  
    // Add file validation with logging
    if (!file.type.startsWith('image/')) {
      console.log('Invalid file type:', file.type);
      return;
    }
  
    if (file.size > 5 * 1024 * 1024) {
      console.log('File too large:', file.size);
      return;
    }
  
    try {
      const formData = new FormData();
      formData.append('avatarImage', file);
      
      // Log the FormData contents
      for (let pair of formData.entries()) {
        console.log('FormData content:', pair[0], pair[1]);
      }
  
      const response = await userService.updateProfile(formData);
      
      if (response) {
        setUserData(response);
        console.log('Profile updated successfully');
      }
    } catch (error) {
      // Enhanced error logging
      console.error('Upload error:', error);
      console.error('Response data:', error.response?.data);
      console.error('Status:', error.response?.status);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const data = await userService.getProfile();
        setUserData(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="text-xl text-black">Loading...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-4xl mx-auto p-4">
        <div className="text-red-500">Error: {error}</div>
      </div>
    );
  }

  if (!userData) return null;

  // Filter fundraisers by status
  const currentFundraisers = userData.fundraisers?.filter(f => f.status === 'active') || [];
  const pastFundraisers = userData.fundraisers?.filter(f => f.status === 'completed' || f.status === 'cancelled') || [];

  // Calculate share statistics
  const totalShares = userData.shareStats?.reduce((total, stat) => total + stat.count, 0) || 0;
  const sharesByType = userData.shareStats?.reduce((acc, stat) => {
    acc[stat.shareType] = stat.count;
    return acc;
  }, {}) || {};

  const handleJoinFundraiser = async () => {
    try {
      await fundraiserService.joinFundraiser(joinCode);
      // Refresh user data to show new fundraiser
      const updatedData = await userService.getProfile();
      setUserData(updatedData);
      setJoinCode('');
      // Show success message
    } catch (error) {
      // Show error message
      console.error('Error joining fundraiser:', error);
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-4 min-h-screen bg-white-900 border border-gray-300 rounded-sm">
      {/* Profile Header */}
      <Card className="mb-8 bg-white border-2 border-white">
        <CardContent className="pt-6">
          <div className="flex items-center gap-6">
            {/* Avatar section with click handling on the wrapper div */}
            <div 
              className="relative group cursor-pointer" 
              onClick={() => fileInputRef.current?.click()}
            >
              <Avatar className="w-24 h-24">
                <AvatarImage 
                  src={userData.avatarImage ? getAvatarImageSrc(userData.avatarImage) : userData.avatar} 
                  alt={userData.username}
                />
                <AvatarFallback>{userData.username?.charAt(0).toUpperCase()}</AvatarFallback>
              </Avatar>
              {/* Hover overlay with instruction text */}
              <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity rounded-full flex items-center justify-center">
                <span className="text-white text-sm">Change Photo</span>
              </div>
              {/* Hidden file input that handles the actual file selection */}
              <input
                type="file"
                ref={fileInputRef}
                className="hidden"
                accept="image/*"
                onChange={handleImageUpload}
              />
            </div>
            <div>
              <h1 className="text-3xl font-bold text-black mb-2">{userData.username}</h1>
              <p className="text-gray-400">{userData.email}</p>
            </div>
          </div>
        </CardContent>
      </Card>
  
      {/* Join & Create Section */}
      <div className="grid gap-4 mb-8">
        <Link to="/fundraisers/create">
          <Button className="w-full bg-white border-2 border-white text-black hover:bg-green-500 hover:border-none">
            Create a Fundraiser
          </Button>
        </Link>
      </div>
  
      {/* Fundraisers Section */}
      <div className="space-y-4">
        {/* Current Fundraisers */}
        <Card className="bg-white border-2 border-white">
          <CardHeader>
            <CardTitle className="text-black">Current Fundraiser(s)</CardTitle>
          </CardHeader>
          <CardContent>
            {currentFundraisers.length > 0 ? (
              <div className="space-y-4">
                {currentFundraisers.map((fundraiser) => (
                  <Link 
                    key={fundraiser._id}
                    to={`/fundraisers/${fundraiser._id}`}
                    className="block p-4 rounded bg-gray-800 hover:bg-gray-700 transition-colors"
                  >
                    <div className="flex justify-between items-center text-black">
                      <span>{fundraiser.title}</span>
                      <span>${fundraiser.currentAmount?.toLocaleString()}</span>
                    </div>
                  </Link>
                ))}
              </div>
            ) : (
              <p className="text-gray-400">No current fundraisers</p>
            )}
          </CardContent>
        </Card>
  
        {/* Past Fundraisers */}
        <Card className="bg-white border-2 border-white">
          <CardHeader 
            className="cursor-pointer"
            onClick={() => setShowPastFundraisers(!showPastFundraisers)}
          >
            <CardTitle className="text-black flex justify-between items-center">
              <span>Past Fundraiser(s)</span>
              <ChevronDown className={`transform transition-transform ${showPastFundraisers ? 'rotate-180' : ''}`} />
            </CardTitle>
          </CardHeader>
          {showPastFundraisers && (
            <CardContent>
              {pastFundraisers.length > 0 ? (
                <div className="space-y-4">
                  {pastFundraisers.map((fundraiser) => (
                    <Link 
                      key={fundraiser._id}
                      to={`/fundraisers/${fundraiser._id}`}
                      className="block p-4 rounded bg-gray-800 hover:bg-gray-700 transition-colors"
                    >
                      <div className="flex justify-between items-center text-black">
                        <span>{fundraiser.title}</span>
                        <span>${fundraiser.currentAmount?.toLocaleString()}</span>
                      </div>
                    </Link>
                  ))}
                </div>
              ) : (
                <p className="text-gray-400">No past fundraisers</p>
              )}
            </CardContent>
          )}
        </Card>
      </div>
  
      {/* Share Statistics */}
      {totalShares > 0 && (
        <Card className="mt-8 bg-white border-2 border-white">
          <CardHeader>
            <CardTitle className="text-black">Share Statistics</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-2 text-black">
              {Object.entries(sharesByType).map(([type, count]) => (
                <div key={type} className="flex justify-between">
                  <span className="capitalize">{type}:</span>
                  <span>{count}</span>
                </div>
              ))}
            </div>
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default UserProfile;