import { Link } from 'react-router-dom';
import { FaInstagram, FaFacebook, FaLinkedin, FaTwitter, FaEnvelope } from 'react-icons/fa';
import logo from '../assets/Simple.svg';
import { useEffect } from 'react';
import { emailService } from '../services/api';
import { useAdmin } from '../contexts/AdminContext';
import InfiniteScrollBanner from '../components/InfiniteScrollingBanner';
import iconBanner from '../assets/iconBanner.png';

function Home() {
  const { isAdmin } = useAdmin();

  useEffect(() => {
    const emailData = {
      recipientEmail: 'mtmusicandart@gmail.com',
      fundraiserTitle: `Sigma Chi Clash Against Cancer`,
      eventLink: 'https://hustlefundraise.com/fundraisers/6739563e0dc5cb71207dc33e',
    };

    const sendTestEmail = async () => {
      try {
        const response = await emailService.sendEmail(emailData);
        console.log('Test email sent:', response);
      } catch (error) {
        console.error('Error sending test email:', error);
      }
    };
    // sendTestEmail();
  }, []);

  return (
    // Main container with adjusted height calculation
    <div className="fixed inset-0 top-[67px] w-full overflow-y-auto">
      <div className="absolute inset-0 bg-white"></div>

      {/* Content wrapper with explicit minimum height and padding for social icons */}
      <div className="relative min-h-[calc(100vh-67px)] flex flex-col pb-16">
        {/* Main content area with auto height */}
        <div className="flex-1 flex flex-col items-center justify-start px-4 pt-4 space-y-4">
          {/* Logo with controlled maximum sizes */}
          <img 
            src={logo} 
            className="w-[90%] max-w-[250px] 
                       sm:max-w-[300px]
                       md:max-w-[400px]
                       lg:max-w-[500px]"
            alt="logo"
          />

          {/* Text content with consistent spacing */}
          <div className="flex flex-col items-center text-center w-full max-w-[90vw] sm:max-w-[80vw] md:max-w-[70vw] space-y-2">
            <p className="text-base sm:text-lg">
              <span className="poppinsItalic">Create</span> a fundraiser with your logo, photos and video.
            </p>
            <p className="text-base sm:text-lg">
              <span className="poppinsItalic">Share</span> your fundraiser through text, email, and social media.
            </p>
            <p className="text-base sm:text-lg">
              <span className="poppinsItalic">Fund</span> your efforts with the support of your family, friends, and community.
            </p>
            <p className="text-base sm:text-lg">
              We are here to help you hustle - with a better way to fundraise!
            </p>
          </div>

          {/* Scroll banner with explicit height container */}
          <div className="w-full h-20 overflow-hidden">
            <InfiniteScrollBanner 
              imageSrc={iconBanner} 
              className="w-full min-w-[800px] sm:min-w-[900px] md:min-w-[1000px] lg:min-w-[1200px]"
            />
          </div>

          {/* Buttons with consistent spacing */}
          <div className="flex flex-col sm:flex-row gap-4 justify-center items-center w-full max-w-[600px] px-4">
            <Link
              to="/fundraisers"
              className="extralight bg-[#28923C] text-black px-6 py-3 rounded-full hover:bg-blue-700 w-full sm:w-auto sm:flex-1 whitespace-nowrap text-center"
            >
              Browse Fundraisers
            </Link>
            <Link
              to="/fundraisers/create"
              className="extralight bg-[#28923C] border-2 border-white text-black px-6 py-3 rounded-full hover:bg-blue-600 w-full sm:w-auto sm:flex-1 text-center"
            >
              Start Fundraising
            </Link>
          </div>
        </div>

        {/* Social icons footer with absolute positioning */}
        <div className="absolute bottom-0 left-0 right-0 bg-white py-2 shadow-top">
          <div className="flex justify-center items-center gap-6">
            <a
              href="https://www.instagram.com/tonyrobbins/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Instagram"
              className="text-black hover:text-opacity-80"
            >
              <FaInstagram size={24} />
            </a>
            <a
              href="https://www.facebook.com/awspublicsector"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Facebook"
              className="text-black hover:text-opacity-80"
            >
              <FaFacebook size={24} />
            </a>
            <a
              href="https://www.linkedin.com/in/officialtonyrobbins/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="LinkedIn"
              className="text-black hover:text-opacity-80"
            >
              <FaLinkedin size={24} />
            </a>
            <a
              href="https://x.com/AWS_Nonprofits"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="X (Twitter)"
              className="text-black hover:text-opacity-80"
            >
              <FaTwitter size={24} />
            </a>
            <a
              href="mailto:info@hustlefundraise.com"
              aria-label="Email"
              className="text-black hover:text-opacity-80"
            >
              <FaEnvelope size={24} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;