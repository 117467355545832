// api.js
import axios from 'axios';

const isProduction = process.env?.NODE_ENV === 'production';
const PROD_URL = 'https://www.hustlefundraise.com'  // 'https://oyster-app-dcrud.ondigitalocean.app';
const DEV_URL = process.env?.REACT_APP_API_URL || 'http://localhost:5000';
const BASE_URL = isProduction ? PROD_URL : DEV_URL;

const API_URL = `${BASE_URL}/api`;
const AUTH_URL = `${BASE_URL}/auth`;

const api = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
});

const publicApi = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Updated response interceptor with more robust auth handlingF
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      // Only redirect if not already on login page and not checking auth status
      const isAuthCheck = error.config.url.includes('/auth/status') || 
                         error.config.url.includes('/users/me');
      const isLoginPage = window.location.pathname === '/login';
      
      if (!isAuthCheck && !isLoginPage) {
        // Save the current path before redirecting
        const currentPath = window.location.pathname;
        if (currentPath !== '/login') {
          localStorage.setItem('authReturnUrl', currentPath);
        }
        window.location.href = '/login';
      }
    }
    return Promise.reject(error);
  }
);

export const authService = {
  login: () => {
    const returnUrl = localStorage.getItem('authReturnUrl') || '/dashboard';
    localStorage.removeItem('authReturnUrl'); // Clean up after getting the URL
    window.location.href = `${AUTH_URL}/google`;
  },

  logout: async () => {
    try {
      await axios.get(`${AUTH_URL}/logout`, { 
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json'
        }
      });
      // Clear any stored auth data
      localStorage.removeItem('authReturnUrl');
      window.location.href = '/login';
    } catch (error) {
      console.error('Logout error:', error);
      window.location.href = '/login';
    }
  },

  getCurrentUser: async () => {
    try {
      const response = await api.get('/users/me');
      return response.data;
    } catch (error) {
      console.error('Get current user error:', error);
      return null;
    }
  },

  hubspotLogin: () => {
    window.location.href = `${AUTH_URL}/hubspot`; // Redirect to your backend's HubSpot auth route
  },

  checkAuth: async () => {
    try {
      const statusResponse = await api.get('/auth/status');
      if (statusResponse.data.isAuthenticated) {
        return {
          isAuthenticated: true,
          user: statusResponse.data.user
        };
      }
  
      const user = await authService.getCurrentUser();
      return {
        isAuthenticated: !!user,
        user
      };
    } catch (error) {
      console.error('Auth check error:', error);
      return { isAuthenticated: false, user: null };
    }
  },

  adminLogin: async (email, password) => {
    try {
      const response = await api.post('/auth/admin/login', { email, password });
      if (response.data.success) {
        localStorage.setItem('adminEmail', email);
      }
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Admin login failed');
    }
  },

  adminLogout: async () => {
    localStorage.removeItem('adminEmail');
    window.location.href = '/admin';
  }

};

export const fundraiserService = {

  getAll: async (params = {}) => {
    try {
      const response = await publicApi.get('/fundraisers/approved');
      console.log('GetAll Response:', response.data);
      return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
      console.error('Error in getAll:', error);
      return [];
    }
  },

  getAllForAdmin: async (params = {}) => {
    try {
      const response = await api.get('/fundraisers');
      console.log('GetAll Admin Response:', response.data);
      return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
      console.error('Error in getAllForAdmin:', error);
      return [];
    }
  },

  create: async (fundraiserData) => {
    try {
      const response = await api.post('/fundraisers', fundraiserData);
      return response.data;
    } catch (error) {
      if (error.response?.data?.error) {
        throw new Error(error.response.data.error);
      }
      throw error;
    }
  },

    joinFundraiser: async (joinCode) => {
      try {
        const response = await api.post('/fundraisers/join', { joinCode });
        return response.data;
      } catch (error) {
        throw new Error(error.response?.data?.message || 'Failed to join fundraiser');
      }
    },

    getById: async (id) => {
      try {
        // Use publicApi instead of api for fetching a single fundraiser
        const response = await publicApi.get(`/fundraisers/${id}`);
        return response.data;
      } catch (error) {
        console.error('Error in getById:', error);
        throw error;
      }
    },

  // getMine: async (a) => {
  //   try {
  //     console.log(a, '<-- caller')
  //     const response = await api.get('/users/my-fundraisers');
  //     console.log('GetMine Response:', response.data);
  //     return response.data?.fundraisers ? response.data?.fundraisers : [];
  //   } catch (error) {
  //     console.error('Error in getMine:', error);
  //     return [];
  //   }
  // },

  getMine: async (a) => {
    try {
      console.log(a, '<-- caller')
      const response = await api.get('/users/my-fundraisers');
      console.log('GetMine Response:', response.data);
      return response.data?.fundraisers ? response.data?.fundraisers : [];
    } catch (error) {
      console.error('Error in getMine:', error);
      return [];
    }
  },

  updateFundraiser: async (id, data) => {
    try {
      const response = await api.put(`/fundraisers/${id}`, data);
      return response.data;
    } catch (error) {
      console.error('Error in update:', error);
      throw error;
    }
  },

 updateFundraiserAmount: async (id, amount) => {
    try {
      // Updated to use the correct endpoint path
      const response = await api.patch(`/fundraisers/${id}/amount`, {
        amount: Number(amount) // Ensure amount is a number
      });
      return response.data;
    } catch (error) {
      console.error('Error updating fundraiser amount:', error);
      throw error;
    }
  },

  delete: async (id) => {
    try {
      const response = await api.delete(`/fundraisers/${id}`);
      return response.data;
    } catch (error) {
      console.error('Error in delete:', error);
      throw error;
    }
  }
};

export const donationService = {
  create: async (donationData) => {
    try {
      const response = await api.post('/donations', donationData);
      return response.data;
    } catch (error) {
      console.error('Error in donation create:', error);
      throw error;
    }
  },

  getByFundraiser: async (fundraiserId) => {
    try {
      const response = await api.get(`/donations/fundraiser/${fundraiserId}`);
      return response.data;
    } catch (error) {
      console.error('Error in getByFundraiser:', error);
      throw error;
    }
  },

  getMine: async () => {
    try {
      const response = await api.get('/donations/my-donations');
      return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
      console.error('Error in getMine donations:', error);
      return [];
    }
  },




// createSquarePayment: async ({ sourceId, amount, fundraiserId, message }) => {
//   try {
//     const response = await api.post('/api/square/payments', {
//       sourceId,
//       amount,
//       fundraiserId,
//       message
//     });
//     return response.data;
//   } catch (error) {
//     console.error('Error creating Square payment:', error);
//     throw error;
//   }
// }

createSquarePayment: async ({ sourceId, amount, fundraiserId, message, isAnonymous, donorName }) => {
  try {
    // Fixed endpoint path - removed extra 'api' prefix
    const response = await api.post('/donations/square/create', {
      sourceId,
      amount,
      fundraiserId,
      message,
      isAnonymous,
      donorName
    });

    return response.data;
  } catch (error) {
    console.error('Error creating Square payment:', error);
    throw error;
  }
}
};

export const userService = {
  getProfile: async () => {
    try {
      const response = await api.get('/users/me');
      return response.data;
    } catch (error) {
      console.error('Error in getProfile:', error);
      throw error;
    }
  },

  updateProfile: async (data) => {
    try {
      let formData;
      
      // Log what kind of data we received
      console.log('Data type received:', data.constructor.name);
      
      // If data is already FormData, use it directly
      if (data instanceof FormData) {
        formData = data;
        // Log FormData contents
        for (let pair of formData.entries()) {
          console.log('FormData entry:', pair[0], pair[1]);
        }
      } else {
        formData = new FormData();
        Object.keys(data).forEach(key => {
          if (key === 'avatarImage' && data[key] instanceof File) {
            formData.append('avatarImage', data[key]);
            console.log('Appended file:', data[key].name);
          } else {
            formData.append(key, data[key]);
            console.log('Appended field:', key, data[key]);
          }
        });
      }
  
      console.log('Sending request to /users/me');
      const response = await api.patch('/users/me', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      
      return response.data;
    } catch (error) {
      console.error('Error in updateProfile:');
      console.error('Status:', error.response?.status);
      console.error('Error data:', error.response?.data);
      throw error;
    }
  },

  trackShare: async (fundraiserId, shareType) => {
    try {
      // Make the API call to track the share
      const response = await api.post('/users/track-share', {
        fundraiserId,
        shareType,
        timestamp: new Date().toISOString() // Include timestamp for accurate tracking
      });
      
      // Return the updated user data from the response
      return response.data;
    } catch (error) {
      // Provide detailed error logging for debugging
      console.error('Error tracking share:', error);
      console.error('Share details:', { fundraiserId, shareType });
      
      // Rethrow the error so the component can handle it
      throw new Error(error.response?.data?.message || 'Failed to track share');
    }
  },

  updateFundraiser: async (id, data) => {
    try {
      const response = await api.put(`/fundraisers/${id}`, data);
      return response.data;
    } catch (error) {
      console.error('Error updating fundraiser:', error);
      throw error;
    }
  },

  getMyFundraisers: async () => {
    try {
      const response = await api.get('/users/my-fundraisers');
      return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
      console.error('Error in getMyFundraisers:', error);
      return [];
    }
  },

  getMyDonations: async () => {
    try {
      const response = await api.get('/users/my-donations');
      return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
      console.error('Error in getMyDonations:', error);
      return [];
    }
  },

  getStats: async () => {
    try {
      const response = await api.get('/users/my-stats');
      return response.data;
    } catch (error) {
      console.error('Error in getStats:', error);
      throw error;
    }
  }
};

export const handleApiError = (error) => {
  if (error.response) {
    return error.response.data.error || 'An error occurred';
  } else if (error.request) {
    return 'No response from server';
  } else {
    return 'Error setting up request';
  }
};

export const emailService = {
  sendAdminEmail: async (emailData) => {
    try {
      const now = Date.now();
      const RATE_LIMIT_MS = 10000; // 10 seconds
      
      // Use admin-specific keys
      const inProgress = localStorage.getItem('adminEmailInProgress');
      if (inProgress) {
        console.log('Admin email send already in progress');
        return {
          success: false,
          rateLimited: true
        };
      }

      const lastEmailSentTime = localStorage.getItem('lastAdminEmailSentTime');
      if (lastEmailSentTime) {
        const timeSinceLastEmail = now - parseInt(lastEmailSentTime);
        if (timeSinceLastEmail < RATE_LIMIT_MS) {
          console.log(`Rate limiting: Ignoring admin email send, ${Math.ceil((RATE_LIMIT_MS - timeSinceLastEmail) / 1000)}s remaining`);
          return { 
            success: false, 
            rateLimited: true 
          };
        }
      }

      try {
        localStorage.setItem('adminEmailInProgress', 'true');
        
        const response = await api.post('/send-admin-email', emailData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        
        localStorage.setItem('lastAdminEmailSentTime', now.toString());
        
        return {
          success: true,
          data: response.data
        };

      } finally {
        localStorage.removeItem('adminEmailInProgress');
      }

    } catch (error) {
      console.error('Error sending admin email:', error);
      return {
        success: false,
        error: error.response?.data?.message || 'Failed to send email'
      };
    }
  },

  sendUserEmail: async (emailData) => {
    try {
      const now = Date.now();
      const RATE_LIMIT_MS = 10000; // 10 seconds
      
      // Use user-specific keys
      const inProgress = localStorage.getItem('userEmailInProgress');
      if (inProgress) {
        console.log('User email send already in progress');
        return {
          success: false,
          rateLimited: true
        };
      }

      const lastEmailSentTime = localStorage.getItem('lastUserEmailSentTime');
      if (lastEmailSentTime) {
        const timeSinceLastEmail = now - parseInt(lastEmailSentTime);
        if (timeSinceLastEmail < RATE_LIMIT_MS) {
          console.log(`Rate limiting: Ignoring user email send, ${Math.ceil((RATE_LIMIT_MS - timeSinceLastEmail) / 1000)}s remaining`);
          return { 
            success: false, 
            rateLimited: true 
          };
        }
      }

      try {
        localStorage.setItem('userEmailInProgress', 'true');
        
        const response = await api.post('/send-user-email', emailData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        
        localStorage.setItem('lastUserEmailSentTime', now.toString());
        
        return {
          success: true,
          data: response.data
        };

      } finally {
        localStorage.removeItem('userEmailInProgress');
      }

    } catch (error) {
      console.error('Error sending user email:', error);
      return {
        success: false,
        error: error.response?.data?.message || 'Failed to send email'
      };
    }
  },

  // You can apply the same pattern to sendUserApprovalEmail if needed
  sendUserApprovalEmail: async (emailData) => {
    try {
      const now = Date.now();
      const RATE_LIMIT_MS = 10000; // 10 seconds
      
      const inProgress = localStorage.getItem('approvalEmailInProgress');
      if (inProgress) {
        console.log('Approval email send already in progress');
        return {
          success: false,
          rateLimited: true
        };
      }

      const lastEmailSentTime = localStorage.getItem('lastApprovalEmailSentTime');
      if (lastEmailSentTime) {
        const timeSinceLastEmail = now - parseInt(lastEmailSentTime);
        if (timeSinceLastEmail < RATE_LIMIT_MS) {
          console.log(`Rate limiting: Ignoring approval email send, ${Math.ceil((RATE_LIMIT_MS - timeSinceLastEmail) / 1000)}s remaining`);
          return { 
            success: false, 
            rateLimited: true 
          };
        }
      }

      try {
        localStorage.setItem('approvalEmailInProgress', 'true');
        
        const response = await api.post('/send-user-approval-email', emailData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        
        localStorage.setItem('lastApprovalEmailSentTime', now.toString());
        
        return {
          success: true,
          data: response.data
        };

      } finally {
        localStorage.removeItem('approvalEmailInProgress');
      }

    } catch (error) {
      console.error('Error sending approval email:', error);
      return {
        success: false,
        error: error.response?.data?.message || 'Failed to send email'
      };
    }
  },

  sendAdminDeleteNotice: async (emailData) => {
    try {
      const now = Date.now();
      const RATE_LIMIT_MS = 10000; // 10 seconds
      
      const inProgress = localStorage.getItem('adminDeleteNoticeInProgress');
      if (inProgress) {
        console.log('Approval email send already in progress');
        return {
          success: false,
          rateLimited: true
        };
      }

      const lastEmailSentTime = localStorage.getItem('adminDeleteNotice');
      if (lastEmailSentTime) {
        const timeSinceLastEmail = now - parseInt(lastEmailSentTime);
        if (timeSinceLastEmail < RATE_LIMIT_MS) {
          console.log(`Rate limiting: Ignoring approval email send, ${Math.ceil((RATE_LIMIT_MS - timeSinceLastEmail) / 1000)}s remaining`);
          return { 
            success: false, 
            rateLimited: true 
          };
        }
      }

      try {
        localStorage.setItem('adminDeleteNoticeInProgress', 'true');
        
        const response = await api.post('/send-admin-delete-notice', emailData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        
        localStorage.setItem('adminDeleteNotice', now.toString());
        
        return {
          success: true,
          data: response.data
        };

      } finally {
        localStorage.removeItem('approvalEmailInProgress');
      }

    } catch (error) {
      console.error('Error sending approval email:', error);
      return {
        success: false,
        error: error.response?.data?.message || 'Failed to send email'
      };
    }
  }
};

export default api;