import * as React from "react"
import { cn } from "../../lib/utils"

const badgeVariants = {
  default: "bg-blue-500 text-black",
  secondary: "bg-gray-800 text-black",
  destructive: "bg-red-500 text-black",
  outline: "border border-white text-black"
}

const Badge = React.forwardRef(({ className, variant = "default", ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      "inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-semibold transition-colors",
      badgeVariants[variant],
      className
    )}
    {...props}
  />
))
Badge.displayName = "Badge"

export { Badge, badgeVariants }