import React, { useState, useEffect, useRef } from 'react';
import { donationService } from '../services/api';
import { Dialog, DialogPortal } from './ui/dialog'; 
import * as DialogPrimitive from "@radix-ui/react-dialog";
import { X } from "lucide-react";

const SQUARE_CONFIG = {
  scriptUrl: 'https://web.squarecdn.com/v1/square.js',
  appId: 'sq0idp-r6aYLcP4DIOWPEg0296qag',
  locationId: 'L13681MRWRXC9'
};

const PRESET_AMOUNTS = [10, 25, 50, 100];
const MIN_DONATION = 1;

const SquarePaymentForm = ({ 
  fundraiserId,
  fundraiserTitle,
  isAuthenticated = false, // Default to false for non-authenticated users
  onSuccess, 
  onError 
}) => {
  const cardContainerRef = useRef(null);
  const [square, setSquare] = useState(null);
  const [card, setCard] = useState(null);
  const [amount, setAmount] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [showMinAmountDialog, setShowMinAmountDialog] = useState(false);
  const [selectedPresetAmount, setSelectedPresetAmount] = useState(null);
  const [donorName, setDonorName] = useState('');
  // If not authenticated, force anonymous to true and don't allow changing it
  const [isAnonymous, setIsAnonymous] = useState(!isAuthenticated);

  useEffect(() => {
    // Always set anonymous to true for non-authenticated users
    if (!isAuthenticated) {
      setIsAnonymous(true);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    let mounted = true;
    let cardInstance = null;

    const initializeSquare = async () => {
      try {
        if (!window.Square) {
          console.log('Loading Square SDK in production mode...');
          const script = document.createElement('script');
          script.src = SQUARE_CONFIG.scriptUrl;
          script.crossOrigin = 'anonymous';
          
          await new Promise((resolve, reject) => {
            script.onload = () => {
              console.log('Square SDK loaded successfully');
              resolve();
            };
            script.onerror = (err) => {
              console.error('Failed to load Square SDK:', err);
              reject(err);
            };
            document.head.appendChild(script);
          });
        }

        await new Promise(resolve => setTimeout(resolve, 1000));

        const payments = await window.Square.payments(
          SQUARE_CONFIG.appId,
          SQUARE_CONFIG.locationId
        );

        if (!mounted) return;
        setSquare(payments);

        console.log('Creating card instance...');
        cardInstance = await payments.card();

        if (!cardContainerRef.current) {
          throw new Error('Card container element not found');
        }

        await cardInstance.attach('#card-container');
        console.log('Card instance attached successfully');
        
        if (mounted) {
          setCard(cardInstance);
          setLoading(false);
        }
      } catch (err) {
        console.error('Square initialization error:', err);
        if (mounted) {
          setError(err.message);
          onError?.(err.message);
          setShowErrorDialog(true);
        }
      }
    };

    if (cardContainerRef.current) {
      console.log('Card container ready, initializing Square...');
      initializeSquare();
    }

    return () => {
      mounted = false;
      if (cardInstance) {
        console.log('Cleaning up card instance...');
        cardInstance.destroy().catch(console.error);
      }
    };
  }, []);

  const handlePresetAmountClick = (presetAmount) => {
    setSelectedPresetAmount(presetAmount);
    setAmount(presetAmount.toString());
  };

  const handleCustomAmountChange = (e) => {
    const value = e.target.value;
    setAmount(value);
    setSelectedPresetAmount(null);
    
    if (value && parseFloat(value) < MIN_DONATION) {
      setShowMinAmountDialog(true);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (parseFloat(amount) < MIN_DONATION) {
      setShowMinAmountDialog(true);
      return;
    }

    setError(null);
    setLoading(true);
  
    try {
      if (!card) {
        throw new Error('Payment form not initialized');
      }
  
      console.log('Processing payment...');
      const result = await card.tokenize();
      
      if (result.status === 'OK' && result.token) {
        console.log('Card tokenized successfully');
        
        const paymentData = {
          sourceId: result.token,
          amount: parseFloat(amount),
          fundraiserId,
          message,
          isAnonymous: !isAuthenticated ? true : isAnonymous, // Force anonymous if not authenticated
          donorName: !isAuthenticated || isAnonymous ? null : donorName
        };

        const response = await donationService.createSquarePayment(paymentData);
  
        if (response.success) {
          console.log('Payment processed successfully');
          onSuccess?.(amount);
          setAmount('');
          setMessage('');
          setDonorName('');
          await card.clear(); 
        } else {
          throw new Error(response.error || 'Payment failed to process');
        }
      } else {
        throw new Error(result.errors?.[0]?.message || 'Payment tokenization failed');
      }
    } catch (err) {
      console.error('Payment error:', err);
      setError(err.message || 'An error occurred during payment');
      setShowErrorDialog(true);
      onError?.(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-full max-w-md mx-auto">
      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Preset amounts with checkbox styling */}
        <div>
          <label className="block text-sm font-medium mb-2 text-gray-700">
            Select Amount (USD)
          </label>
          <div className="grid grid-cols-2 gap-4 mb-4">
            {PRESET_AMOUNTS.map((presetAmount) => (
              <div key={presetAmount} className="flex items-center space-x-2 p-2 border rounded-md bg-white">
                <input
                  type="checkbox"
                  id={`amount-${presetAmount}`}
                  checked={selectedPresetAmount === presetAmount}
                  onChange={() => handlePresetAmountClick(presetAmount)}
                  className="h-5 w-5 cursor-pointer appearance-none rounded border-2 border-blue-500 bg-white transition-colors checked:bg-blue-600 checked:border-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500/30"
                />
                <label 
                  htmlFor={`amount-${presetAmount}`} 
                  className="flex-1 text-gray-700 cursor-pointer select-none text-center"
                >
                  ${presetAmount}
                </label>
              </div>
            ))}
          </div>
        </div>
   
        {/* Custom amount */}
        <div>
          <label className="block text-sm font-medium mb-2 text-gray-700">
            Or Enter Custom Amount (Minimum $10)
          </label>
          <div className="relative">
            <span className="absolute left-3 top-2 text-gray-500">$</span>
            <input
              type="number"
              min={MIN_DONATION}
              step="0.01"
              value={amount}
              onChange={handleCustomAmountChange}
              className="w-full pl-8 pr-3 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              required
              placeholder={MIN_DONATION.toFixed(2)}
            />
          </div>
        </div>
   
        {/* Card details */}
        <div>
          <label className="block text-sm font-medium mb-2 text-gray-700">
            Card Details
          </label>
          <div 
            id="card-container"
            ref={cardContainerRef}
            className="p-3 border rounded-md min-h-[100px] bg-white"
          />
        </div>
   
        {/* Name field - only shown if authenticated and not anonymous */}
        {isAuthenticated && !isAnonymous && (
          <div>
            <label className="block text-sm font-medium mb-2 text-gray-700">
              Your Name
            </label>
            <input
              type="text"
              value={donorName}
              onChange={(e) => setDonorName(e.target.value)}
              className="w-full px-3 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              placeholder="Enter your name (optional)"
            />
          </div>
        )}
   
        {/* Message field */}
        <div>
          <label className="block text-sm font-medium mb-2 text-gray-700">
            Message (optional)
          </label>
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="w-full px-3 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            rows={3}
            maxLength={500}
            placeholder="Leave a message with your donation"
          />
        </div>
   
        {/* Anonymous toggle - only show if authenticated */}
        {isAuthenticated && (
          <div className="flex items-center space-x-3">
            <div className="relative flex items-center">
              <input
                type="checkbox"
                id="anonymous"
                checked={isAnonymous}
                onChange={(e) => setIsAnonymous(e.target.checked)}
                className="peer h-5 w-5 cursor-pointer appearance-none rounded border-2 border-blue-500 bg-white transition-colors checked:bg-blue-600 checked:border-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500/30"
              />
              <svg
                className="pointer-events-none absolute h-4 w-4 opacity-0 peer-checked:opacity-100 text-white"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={3}
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
              </svg>
            </div>
            <label htmlFor="anonymous" className="text-sm font-medium text-gray-700 cursor-pointer select-none">
              Make donation anonymous
            </label>
          </div>
        )}

        {/* Anonymous status message for non-authenticated users */}
        {!isAuthenticated && (
          <div className="text-sm text-gray-500 italic">
            Guest donations are always anonymous
          </div>
        )}
   
        {/* Submit button */}
        <button
          type="submit"
          disabled={loading || !card || !amount || parseFloat(amount) < MIN_DONATION}
          className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
        >
          {loading ? (
            <span className="flex items-center justify-center">
              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Processing...
            </span>
          ) : (
            `Donate $${amount || '0'}`
          )}
        </button>
      </form>
   
      {/* Error dialog */}
      <Dialog open={showErrorDialog} onOpenChange={setShowErrorDialog}>
        <DialogPortal>
          <DialogPrimitive.Overlay className="fixed inset-0 z-50 bg-black/50 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0" />
          <DialogPrimitive.Content className="fixed left-[50%] top-[50%] z-50 grid w-full max-w-lg translate-x-[-50%] translate-y-[-50%] gap-4 border bg-white p-6 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg">
            <DialogPrimitive.Title className="text-lg font-semibold text-gray-900">
              Payment Error
            </DialogPrimitive.Title>
            <DialogPrimitive.Description className="text-sm text-gray-500">
              {error}
            </DialogPrimitive.Description>
            <DialogPrimitive.Close className="absolute right-4 top-4 rounded-sm opacity-70 hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2">
              <X className="h-4 w-4" />
              <span className="sr-only">Close</span>
            </DialogPrimitive.Close>
          </DialogPrimitive.Content>
        </DialogPortal>
      </Dialog>
   
 {/* Minimum amount dialog */}
<Dialog open={showMinAmountDialog} onOpenChange={setShowMinAmountDialog}>
  <DialogPortal>
    <DialogPrimitive.Overlay className="fixed inset-0 z-50 bg-black/50 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0" />
    <DialogPrimitive.Content className="fixed left-[50%] top-[50%] z-50 grid w-full max-w-lg translate-x-[-50%] translate-y-[-50%] gap-4 border bg-white p-6 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg">
      <DialogPrimitive.Title className="text-lg font-semibold text-gray-900">
        Minimum Donation Amount
      </DialogPrimitive.Title>
      <DialogPrimitive.Description className="text-sm text-gray-500">
        The minimum donation amount is ${MIN_DONATION}. Please adjust your donation amount.
      </DialogPrimitive.Description>
      <DialogPrimitive.Close className="absolute right-4 top-4 rounded-sm opacity-70 hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2">
        <X className="h-4 w-4" />
        <span className="sr-only">Close</span>
      </DialogPrimitive.Close>
    </DialogPrimitive.Content>
  </DialogPortal>
</Dialog>

</div>
  );
};

export default SquarePaymentForm;