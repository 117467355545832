import React from 'react';

const InfiniteScrollBanner = ({ imageSrc }) => {
  return (
    <div className="w-[50vw] mx-auto overflow-hidden relative mt-5 -mb-7">
      <div className="flex animate-scroll">
        {/* We duplicate the image twice to create the infinite effect */}
        <img src={imageSrc} alt="Icon Banner" className="w-full object-contain" />
        <img src={imageSrc} alt="Icon Banner" className="w-full object-contain" />
      </div>

      <style jsx>{`
        @keyframes scroll {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(-50%);
          }
        }

        .animate-scroll {
          animation: scroll 20s linear infinite;
        }
      `}</style>
    </div>
  );
};

export default InfiniteScrollBanner;