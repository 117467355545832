import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { fundraiserService, authService } from '../services/api';
import { getImageSrc } from '../utils/imageHandler';
import ImageCarousel from '../components/ImageCarousel';
// import DonateModal from '../components/DonateModal';
import { useNavigate } from 'react-router-dom';
import { FaFacebook, FaTwitter, FaLinkedin  } from 'react-icons/fa';
import { HiMail, HiLink, HiShare } from 'react-icons/hi';
import { emailService, userService } from '../services/api';
import { Avatar, AvatarImage, AvatarFallback } from "../components/ui/avatar";

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
  DialogDescription,
} from "../components/ui/dialog";

import SquarePaymentForm from '../components/SquarePaymentForm';
import { useAdmin } from '../contexts/AdminContext';

// Still inside FundraiserDetails component...

// In FundraiserDetails.js, add this component at the top
const JoinCodeDisplay = ({ joinCode }) => {
  const [copied, setCopied] = useState(false);

  const handleCopyCode = async () => {
    try {
      await navigator.clipboard.writeText(joinCode);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Error copying code:', err);
    }
  };

  return (
    <div className="bg-gray-800 p-4 rounded-lg mt-4">
      <div className="flex justify-between items-center">
        <div>
          <h3 className="text-black font-semibold mb-1">Team Join Code</h3>
          <p className="text-sm text-gray-400">Share this code with team members</p>
        </div>
        <div className="flex items-center space-x-2">
          <code className="bg-white px-3 py-1 rounded text-black">{joinCode}</code>
          <button
            onClick={handleCopyCode}
            className="bg-blue-500 text-black px-3 py-1 rounded hover:bg-blue-600 transition-colors"
          >
            {copied ? 'Copied!' : 'Copy'}
          </button>
        </div>
      </div>
    </div>
  );
};

const ShareButtons = ({ title, url, fundraiserId }) => {
  const [copied, setCopied] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const shareText = `Check out this fundraising event by Hustlefundraise.com: ${title}`;
  
  // New function to track share attempts - note we can access 'id' from the parent scope
  const trackShareAttempt = async (shareType) => {
    try {
      await userService.trackShare(fundraiserId, shareType);
    } catch (error) {
      console.error('Error tracking share:', error);
    }
  };

  const handleNativeShare = async () => {
    // First, check if the Web Share API is available in the browser
    if (navigator.share) {
      try {
        // First attempt the share operation
        await navigator.share({
          title: title,
          text: shareText,
          url: url
        });
  
        // If share was successful, track this share attempt
        try {
          await trackShareAttempt('webshare');
          console.log('Share tracked successfully');
        } catch (trackingError) {
          // If tracking fails, we still consider the share successful
          // We just log the tracking error without affecting user experience
          console.error('Share completed but tracking failed:', trackingError);
        }
  
        console.log('Shared successfully');
      } catch (shareError) {
        // Handle share operation errors
        console.log('Error during share operation:', shareError);
        
        // If share fails (user cancels or other error), we don't track it
        // Instead, we fall back to the dialog
        setIsDialogOpen(true);
      }
    } else {
      // If Web Share API isn't available, fall back to dialog
      console.log('Web Share API not supported, showing dialog');
      setIsDialogOpen(true);
    }
  };

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(`${shareText}\n${url}`);
      await trackShareAttempt('copy'); // Add tracking
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.log('Error copying:', err);
    }
  };

  const shareLinks = {
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&quote=${encodeURIComponent(shareText)}`,
    twitter: `https://twitter.com/intent/tweet?text=${encodeURIComponent(shareText)}&url=${encodeURIComponent(url)}`,
    linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}&summary=${encodeURIComponent(shareText)}`,
    email: `mailto:?subject=${encodeURIComponent(title)}&body=${encodeURIComponent(`${shareText}\n\n${url}`)}`
  };
  
  // Remove duplicate LinkedIn entry and update share options with tracking
  const shareOptions = [
    {
      icon: FaFacebook,
      label: 'Facebook',
      onClick: async () => {
        await trackShareAttempt('facebook');
        window.open(shareLinks.facebook, '_blank', 'noopener,noreferrer');
      },
      className: 'hover:text-[#1877F2]'
    },
    {
      icon: FaLinkedin,
      label: 'LinkedIn',
      onClick: async () => {
        await trackShareAttempt('linkedin');
        window.open(shareLinks.linkedin, '_blank', 'noopener,noreferrer');
      },
      className: 'hover:text-[#0A66C2]'
    },
    {
      icon: FaTwitter,
      label: 'X (Twitter)',
      onClick: async () => {
        await trackShareAttempt('twitter');
        window.open(shareLinks.twitter, '_blank', 'noopener,noreferrer');
      },
      className: 'hover:text-gray-400'
    },
    {
      icon: HiMail,
      label: 'Email',
      onClick: async () => {
        await trackShareAttempt('email');
        const emailLink = document.createElement('a');
        emailLink.href = shareLinks.email;
        emailLink.target = '_blank';
        emailLink.rel = 'noopener noreferrer';
        emailLink.click();
      },
      className: 'hover:text-gray-400'
    },
    {
      icon: HiLink,
      label: copied ? 'Copied!' : 'Copy Link',
      onClick: handleCopyLink,
      className: 'hover:text-gray-400'
    }
  ];

  return (
    <>
      <button
        onClick={handleNativeShare}
        className="inline-flex items-center gap-2 bg-white text-black px-4 py-2 rounded border-2 border-white hover:bg-green-500 hover:text-white transition-colors"
      >
        <HiShare size={20} />
        <span className="min-[360px]:inline hidden">Share</span>
      </button>

      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent className="w-72 p-4 bg-white border-2 border-white">
          <div className="grid gap-4">
            {shareOptions.map((option, index) => (
              <button
                key={index}
                onClick={async () => {
                  await option.onClick();
                  setIsDialogOpen(false);
                }}
                className={`flex items-center gap-3 w-full px-4 py-3 text-black rounded-lg hover:bg-gray-800 transition-colors ${option.className}`}
              >
                <option.icon size={20} />
                <span className="text-sm">{option.label}</span>
              </button>
            ))}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};


function FundraiserDetails() {

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);

  const navigate = useNavigate();
  const { id } = useParams();
  const [fundraiser, setFundraiser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isCreator, setIsCreator] = useState(false);
  const [showThankYouDialog, setShowThankYouDialog] = useState(false);
  const [donationAmount, setDonationAmount] = useState(0);
  const { isAdmin } = useAdmin();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showPostActionDialog, setShowPostActionDialog] = useState(false);
  const [deletedFundraiserInfo, setDeletedFundraiserInfo] = useState(null);
  const [donorData, setDonorData] = useState({});

  const isEnded = (endDate) => {
    return endDate && new Date(endDate) < new Date();
  };

  const handleDonationSuccess = async (amount, id, setFundraiser) => {
    console.log('Donation successful!', amount, id); // Log for debugging
    setDonationAmount(amount);
  
    try {
      // Update the fundraiser's current amount
      await fundraiserService.updateFundraiserAmount(id, amount);
  
      // Refetch the fundraiser data to update the UI
      const data = await fundraiserService.getById(id);
      setFundraiser(data);
      setShowThankYouDialog(true);
    } catch (error) {
      console.error('Error updating fundraiser amount:', error);
    }
  };

  const handleDelete = async () => {
    try {
      // Store fundraiser info first
      const fundraiserInfo = {
        title: fundraiser.title,
        approved: fundraiser.approved,
        eventLink: window.location.href
      };
  
      // Set the info for our final dialog
      setDeletedFundraiserInfo(fundraiserInfo);
      
      // Close the confirm dialog
      setShowDeleteDialog(false);
  
      // If it was approved, send the email
      if (fundraiserInfo.approved) {
        try {
          await emailService.sendAdminDeleteNotice({
            fundraiserTitle: fundraiserInfo.title,
            eventLink: fundraiserInfo.eventLink,
            recipientEmail: 'info@hustlefundraise.com'
          });
        } catch (emailError) {
          console.error('Error sending admin delete notification:', emailError);
        }
      }
  
      // Delete the fundraiser
      await fundraiserService.delete(id);
      
      // Now show the final dialog
      setShowPostActionDialog(true);
  
    } catch (error) {
      console.error('Error in deletion process:', error);
      setError('Failed to delete fundraiser');
    }
  };
  

  const handleThankYouDialogClose = () => {
    setShowThankYouDialog(false);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  
  // onMount useEffect

useEffect(() => {
  let mounted = true;  // For handling cleanup/unmounting

  const fetchAllData = async () => {
    try {
      setLoading(true);

      // Fetch all data in parallel using Promise.all
      // This is more efficient than sequential fetches
      const [authStatus, currentUserData, fundraiserData] = await Promise.all([
        authService.checkAuth(),
        authService.getCurrentUser(),
        fundraiserService.getById(id)
      ]);

      // Only update state if component is still mounted
      if (!mounted) return;

      // Handle auth status
      setIsAuthenticated(authStatus.isAuthenticated);
      setUser(authStatus.user);

      // Handle current user
      console.log('Received user:', currentUserData);
      setCurrentUser(currentUserData);

      // Handle fundraiser data
      setFundraiser(fundraiserData);
      console.log(fundraiserData, "dats");

      // Set creator status - we can do this here since we have both user and fundraiser data
      if (currentUserData && fundraiserData.creator) {
        setIsCreator(fundraiserData.creator._id === currentUserData._id);
      }

    } catch (error) {
      // Handle all possible errors
      console.error('Error fetching data:', error);
      
      // Set appropriate error states
      setIsAuthenticated(false);
      setUser(null);
      setCurrentUser(null);
      setError('Failed to load fundraiser details');

    } finally {
      // Only update loading state if component is still mounted
      if (mounted) {
        setLoading(false);
      }
    }
  };

  fetchAllData();

  // Cleanup function to prevent memory leaks and state updates after unmount
  return () => {
    mounted = false;
  };
}, [id]); // Only depend on id since it's the only external value we need

  const getYoutubeEmbedUrl = (url) => {
    if (!url) return null;
    const match = url.match(/(?:youtu\.be\/|youtube\.com\/(?:watch\?v=|youtube\.com\/(?:embed\/|v\/))([^?&"'>]+))/);
    return match ? `https://www.youtube.com/embed/${match[1]}?autoplay=0&controls=1&showinfo=0&rel=0&modestbranding=1` : null;
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-[50vh]">
        <div className="text-xl text-black">Loading...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-4xl mx-auto px-4 py-8">
        <div className="bg-red-900 text-black p-4 rounded-lg mb-4">{error}</div>
        <Link to="/fundraisers" className="text-blue-400 hover:text-blue-300">
          ← Back to fundraisers
        </Link>
      </div>
    );
  }

  if (!fundraiser) {
    return (
      <div className="max-w-4xl mx-auto px-4 py-8">
        <div className="text-center">
          <h2 className="text-2xl font-bold mb-4 text-black">Fundraiser not found</h2>
          <Link to="/fundraisers" className="text-blue-400 hover:text-blue-300">
            ← Back to fundraisers
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-8">
        {/* Main content column - takes up 3/4 width */}
        <div className="lg:col-span-3">
          {/* Top navigation row */}
          <div className="flex justify-between items-center mb-6">
          <Link 
  to={isAdmin ? "/dashboard" : "/fundraisers"}
  className="inline-flex items-center text-blue-400 hover:text-white hover:bg-green-500 px-4 py-2 rounded-sm"
>
  {isAdmin ? "← Back to Dashboard" : "← Back to Fundraisers"}
</Link>
  
            {isCreator && fundraiser.isTeamBased && fundraiser.joinCode && (
              <JoinCodeDisplay joinCode={fundraiser.joinCode} />
            )}
            
            <div className="flex gap-2">
              <ShareButtons 
                title={fundraiser.title}
                url={window.location.href}
                fundraiserId={id}  
              />
              {isAdmin ? (
                <Link
                  to={`/fundraisers/${id}/edit`}
                  className="inline-block bg-white text-black px-4 py-2 rounded border-2 border-white hover:bg-green-500 transition-colors hover:text-white"
                >
                  <span className="min-[360px]:inline hidden">Edit Fundraiser</span>
                  <span className="min-[360px]:hidden inline">Edit</span>
                </Link>
              ) : isCreator && (
                <button
                  onClick={() => setShowDeleteDialog(true)}
                  className={`inline-block text-black px-4 py-2 rounded transition-colors ${
                    !fundraiser.approved 
                      ? "bg-yellow-500 hover:bg-yellow-600" 
                      : "bg-red-500 hover:bg-red-600"
                  }`}
                >
                  <span className="min-[360px]:inline hidden">
                    {!fundraiser.approved ? 'Cancel Fundraiser' : 'Delete Fundraiser'}
                  </span>
                  <span className="min-[360px]:hidden inline">
                    {!fundraiser.approved ? 'Cancel' : 'Delete'}
                  </span>
                </button>
              )}
            </div>
          </div>
  
          {/* Main content card */}
          <div className="bg-white rounded-lg shadow-lg overflow-hidden border-2 border-white">
            <div className="p-6">
              {/* Team Members Section */}
              {fundraiser.isTeamBased && fundraiser.teamMembers?.length > 0 && (
                <div className="mb-6">
                  <h3 className="text-xl font-semibold text-black mb-4">Team Members</h3>
                  <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                    {fundraiser.teamMembers.map(member => (
                      <div key={member._id} className="flex items-center gap-3 bg-gray-800 p-3 rounded">
                        <Avatar className="w-10 h-10">
                          <AvatarImage src={member.user.avatar} />
                          <AvatarFallback>{member.user.username[0]}</AvatarFallback>
                        </Avatar>
                        <div>
                          <p className="text-black text-sm font-medium">{member.user.username}</p>
                          <p className="text-xs text-gray-400">
                            Joined {new Date(member.joinedAt).toLocaleDateString()}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
  
              {/* Fundraiser header */}
              <div className="flex justify-between items-start mb-6">
                <div>
                  <h1 className="text-3xl font-bold mb-2 text-black">{fundraiser.title}</h1>
                  <p className="text-gray-300">
                    Created by {fundraiser.creator?.username || 'Anonymous'} on{' '}
                    {new Date(fundraiser.createdAt).toLocaleDateString()}
                  </p>
                  
                  {fundraiser.tags && fundraiser.tags.length > 0 && (
                    <div className="mt-2 flex flex-wrap gap-2">
                      {fundraiser.tags.map(tag => (
                        <span 
                          key={tag} 
                          className="px-2 py-1 bg-gray-800 text-black text-sm rounded"
                        >
                          {tag}
                        </span>
                      ))}
                    </div>
                  )}
                </div>
              </div>
  
              {/* Description */}
              <div className="prose max-w-none mb-6">
                <p className="whitespace-pre-line text-black">{fundraiser.description}</p>
              </div>
  
              {/* Images Section */}
              {fundraiser.images && fundraiser.images.length > 0 && (
                <div className="mb-8">
                  <Dialog>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                      {fundraiser.images.map((image, index) => (
                        <DialogTrigger key={index} asChild>
                          <img
                            src={getImageSrc(image)}
                            alt={`Fundraiser image ${index + 1}`}
                            className="rounded-lg w-full h-48 object-cover cursor-pointer hover:opacity-90 transition-opacity"
                          />
                        </DialogTrigger>
                      ))}
                    </div>
                    <DialogContent className="max-w-screen-lg w-full bg-white border-2 border-white">
                      <DialogHeader>
                        <DialogTitle className="text-center text-black">
                          {fundraiser.title} - Images
                        </DialogTitle>
                      </DialogHeader>
                      <ImageCarousel images={fundraiser.images} />
                    </DialogContent>
                  </Dialog>
                </div>
              )}
  
              {/* Video Section */}
              {fundraiser.videoUrl && (
                <div className="mb-8">
                  <div className="aspect-w-16 aspect-h-9">
                    <iframe
                      src={getYoutubeEmbedUrl(fundraiser.videoUrl)}
                      title="Fundraiser video"
                      className="w-full h-[400px] rounded-lg"
                      allowFullScreen
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    ></iframe>
                  </div>
                </div>
              )}
  
              {/* Progress Bar */}
              <div className="mb-8">
                <div className="flex justify-between mb-2">
                  <span className="text-lg text-black">Progress</span>
                  <span className="text-lg font-semibold text-black">
                    ${(fundraiser.currentAmount || 0).toLocaleString()} of ${(fundraiser.goal || 0).toLocaleString()}
                  </span>
                </div>
                <div className="w-full bg-gray-800 h-3">
                  <div
                    className="bg-green-500 h-3 transition-all duration-500"
                    style={{
                      width: `${Math.min((fundraiser.currentAmount / fundraiser.goal) * 100, 100)}%`
                    }}
                  />
                </div>
                <div className="text-black">
                  {((fundraiser.currentAmount / fundraiser.goal) * 100).toFixed(0)}% raised
                </div>
                {fundraiser.endDate && (
                  <div className="mt-2 text-sm text-black">
                    End Date: {new Date(fundraiser.endDate).toLocaleDateString()}
                  </div>
                )}
              </div>
  
              {/* Updates Section */}
              {fundraiser.updates && fundraiser.updates.length > 0 && (
                <div className="mb-8">
                  <h2 className="text-xl font-semibold mb-4 text-black">Updates</h2>
                  <div className="space-y-4">
                    {fundraiser.updates.map((update, index) => (
                      <div key={index} className="bg-gray-900 rounded-lg p-4">
                        <div className="text-sm text-gray-300 mb-2">
                          {new Date(update.date).toLocaleDateString()}
                        </div>
                        <p className="whitespace-pre-line text-black">{update.content}</p>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
  
          {/* Donation Form Section */}
          <div className="mt-8 bg-white rounded-lg border-2 border-white p-6">
            {isEnded(fundraiser.endDate) ? (
              <div className="text-center py-6">
                <p className="text-xl text-black">
                  This Fundraiser has <span className="text-yellow-500">ended</span>
                </p>
              </div>
            ) : (

              
            fundraiser.approved &&
              <SquarePaymentForm
                // Basic fundraiser information
                fundraiserId={id}
                fundraiserTitle={fundraiser?.title || ''}
                
                // Authentication and user information
                isAuthenticated={isAuthenticated}
                userName={currentUser?.username}  // Using currentUser since it's from getCurrentUser()
                
                // Success and error handlers
                onSuccess={(amount) => handleDonationSuccess(amount, id, setFundraiser)}
                onError={(errorMessage) => {
                  console.error('Donation failed:', errorMessage);
                }}
              />


            
            )}
          </div>
        </div>
  
        {/* Side Panel for Donations - 1/4 width */}
        <div className="lg:col-span-1">
          <div className="sticky top-4 bg-white rounded-lg border-2 border-white">
            <div className="p-4 border-b border-gray-800">
              <h2 className="text-xl font-semibold text-black">{fundraiser.approved? 'Recent Donations': 'Pending Approval'}</h2>
            </div>
            <div className="p-4 max-h-[calc(100vh-8rem)] overflow-y-auto">
              {fundraiser.donations && fundraiser.donations.length > 0 ? (
                <div className="space-y-4">
               {fundraiser.donations.map(donation => (
  <div key={donation._id} className="border-b border-gray-700 pb-4 last:border-0">
    <div className="flex items-start gap-3">
      <Avatar className="w-8 h-8 flex-shrink-0">
        <AvatarImage src={donation.isAnonymous ? null : donation.donor?.avatar} />
        <AvatarFallback>{donation.isAnonymous ? 'A' : donation.donorName?.[0]}</AvatarFallback>
      </Avatar>
      <div className="flex-1">
        <div className="flex justify-between items-start">
          <span className="text-black font-medium">
            {donation.isAnonymous ? 'Anonymous' : donation.donorName || 'Unknown'}
          </span>
          <span className="text-black font-bold">
            ${donation.amount.toLocaleString()}
          </span>
        </div>
        <span className="text-xs text-gray-400 block">
          {new Date(donation.createdAt).toLocaleDateString()}
        </span>
        {donation.message && (
          <p className="text-gray-300 text-sm mt-2">{donation.message}</p>
        )}
      </div>
    </div>
  </div>
))}

                </div>
              ) : (
                <p className="text-gray-400 text-center">{fundraiser.approved ? 'No donations yet. Be the first to donate!' : '' }</p>
              )}
            </div>
          </div>
        </div>
      </div>

      <Dialog open={showThankYouDialog} onOpenChange={handleThankYouDialogClose}>
        <DialogContent className="bg-white border-2 border-white">
          <DialogHeader>
            <DialogTitle className="text-2xl font-bold text-black mb-4">
              Thank You for Your Donation!
            </DialogTitle>
            <div className="text-lg text-gray-300">
              <span className="block mb-4">
                Your donation of ${Number(donationAmount).toLocaleString()} has been successfully processed.
              </span>
              <span className="block mb-4">
                Your generosity helps make a difference for {fundraiser?.title}.
              </span>
              <div className="mt-6">
                <button
                  onClick={handleThankYouDialogClose}
                  className="w-full bg-blue-600 text-black py-2 px-4 rounded-md hover:bg-blue-700 transition-colors"
                >
                  Close
                </button>
              </div>
            </div>
          </DialogHeader>
        </DialogContent>
      </Dialog>

      <Dialog open={showDeleteDialog} onOpenChange={setShowDeleteDialog}>
  <DialogContent>
    <DialogHeader>
      <DialogTitle>
        {!fundraiser.approved ? 'Cancel Fundraiser' : 'Delete Fundraiser'}
      </DialogTitle>
      <DialogDescription>
        <div className='text-black'>
          Are you sure you want to {!fundraiser.approved ? 'cancel' : 'delete'} this fundraiser? This action cannot be undone.
        </div>
      </DialogDescription>
    </DialogHeader>
    <DialogFooter className="gap-2">
      <button
        type="button"
        onClick={() => setShowDeleteDialog(false)}
        className="px-4 py-2 border border-gray-300 rounded hover:bg-gray-50 transition-colors text-black"
      >
        Close
      </button>
      <button
        type="button"
        onClick={handleDelete}
        className={`px-4 py-2 text-black rounded transition-colors ${
          !fundraiser.approved 
            ? "bg-yellow-500 hover:bg-yellow-600" 
            : "bg-red-500 hover:bg-red-600"
        }`}
      >
        {!fundraiser.approved ? 'Confirm' : 'Delete'}
      </button>
    </DialogFooter>
  </DialogContent>
</Dialog>

<Dialog open={showPostActionDialog}>
  <DialogContent>
    <DialogHeader>
      <DialogTitle className="text-black mb-4">
        {deletedFundraiserInfo?.approved ? 'Fundraiser Deleted' : 'Fundraiser Canceled'}
      </DialogTitle>
      <DialogDescription>
        <div className="text-black">
          {!deletedFundraiserInfo?.approved ? (
            'Your fundraiser has been canceled. Hope to see you back again soon!'
          ) : (
            <>
              Your fundraiser has been deleted. Please contact the administrator at{' '}
              <a 
                href="mailto:info@hustlefundraise.com"
                className="text-blue-400 hover:text-blue-300 underline"
              >
                info@hustlefundraise.com
              </a>
              {' '}with any questions or concerns. Thank you!
            </>
          )}
        </div>
      </DialogDescription>
    </DialogHeader>
    <DialogFooter>
      <button
        onClick={() => navigate('/dashboard')}
        className="w-full bg-blue-500 text-black py-2 px-4 rounded hover:bg-blue-600 transition-colors"
      >
        Close
      </button>
    </DialogFooter>
  </DialogContent>
</Dialog>
    </div>
  );  
}

export default FundraiserDetails;