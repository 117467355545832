// contexts/AdminContext.jsx
import React, { createContext, useContext, useState, useEffect } from 'react';
import { authService } from '../services/api';

const AdminContext = createContext({
  isAdmin: false,
  setIsAdmin: () => {}
});

export const AdminProvider = ({ children }) => {
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      const { isAuthenticated, user } = await authService.checkAuth();
      setIsAdmin(isAuthenticated && user?.email === 'info@hustlefundraise.com'); // ++++++++ leifworkmaster
    };

    checkAuth();
  }, []);

  return (
    <AdminContext.Provider value={{ isAdmin, setIsAdmin }}>
      {children}
    </AdminContext.Provider>
  );
};

export const useAdmin = () => useContext(AdminContext);