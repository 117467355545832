import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AdminProvider } from './contexts/AdminContext';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import AdminLogin from './pages/AdminLogin';
import AdminDashboard from './pages/AdminDashboard';
import BrowseFundraisers from './pages/BrowseFundraisers';
import CreateFundraiser from './pages/CreateFundraiser';
import ProtectedRoute from './components/ProtectedRoute';
import FundraiserDetails from './pages/FundraiserDetails';
import EditFundraiser from './pages/EditFundraiser'
import UserProfile from './pages/Profile'
import Contact from './pages/Contact'; 

function App() {
  return (
    <BrowserRouter>
      <AdminProvider>
        <div className="min-h-screen bg-gray-50" style={{backgroundColor: 'white'}}>
          {/* Only show navbar on non-admin routes */}
          {!window.location.pathname.startsWith('/admin') &&   <div className=" z-4000 mb-2"><Navbar/></div>}
          
          <main className="container mx-auto px-4 py-8">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/contact" element={<Contact />} />  {/* Add this route */}
              <Route path="/profile" element={<ProtectedRoute><UserProfile /></ProtectedRoute>} />
              <Route path="/fundraisers" element={<BrowseFundraisers />} />
              <Route path="/fundraisers/:id" element={<FundraiserDetails />} />
              <Route 
                path="/fundraisers/:id/edit" 
                element={<ProtectedRoute><EditFundraiser /></ProtectedRoute>}
              />
              <Route 
                path="/dashboard" 
                element={<ProtectedRoute><Dashboard /></ProtectedRoute>} 
              />
              <Route path="/login" element={<Login />} />
              <Route 
                path="/fundraisers/create" 
                element={<ProtectedRoute><CreateFundraiser /></ProtectedRoute>}
              />
              
              {/* Admin routes */}
              <Route path="/admin" element={<AdminLogin />} />
              <Route
                path="/admin/dashboard"
                element={<ProtectedRoute><AdminDashboard /></ProtectedRoute>}
              />
            </Routes>
          </main>
        </div>
      </AdminProvider>
    </BrowserRouter>
  );
}

export default App;