import { useEffect, useState, useCallback } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { authService } from '../services/api';

function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const checkAuthStatus = useCallback(async () => {
    try {
      const { isAuthenticated, user } = await authService.checkAuth();
      
      if (isAuthenticated && user) {
        // Check localStorage first, then location state, then default to dashboard
        const returnUrl = localStorage.getItem('authReturnUrl') 
          || location.state?.from 
          || '/dashboard';
        
        // Clean up stored return URL
        localStorage.removeItem('authReturnUrl');
        
        // Navigate to the return URL
        navigate(returnUrl, { replace: true });
      }
    } catch (error) {
      console.error('Login auth check error:', error);
      setError('Failed to check authentication status');
    } finally {
      setIsLoading(false);
    }
  }, [navigate, location.state?.from]);

  useEffect(() => {
    // Check for various error conditions in URL parameters
    const authFailed = searchParams.get('authFailed');
    const errorMessage = searchParams.get('error');
    
    if (authFailed) {
      setError('Authentication failed. Please try again.');
      setIsLoading(false);
      return;
    }

    if (errorMessage) {
      setError(decodeURIComponent(errorMessage));
      setIsLoading(false);
      return;
    }

    // Check if we were redirected with an error state
    if (location.state?.error) {
      setError(location.state.error);
      setIsLoading(false);
      return;
    }

    checkAuthStatus();
  }, [checkAuthStatus, searchParams, location.state?.error]);

  const handleGoogleLogin = async () => {
    try {
      setError(null);
      
      // Store the return URL in localStorage before redirecting
      const returnUrl = location.state?.from || '/dashboard';
      localStorage.setItem('authReturnUrl', returnUrl);
      
      // Use the authService login method instead of direct window.location
      await authService.login();
    } catch (error) {
      console.error('Google login error:', error);
      setError('Failed to initiate login. Please try again.');
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="flex flex-col items-center space-y-4">
          <div className="text-lg">Loading...</div>
          <div className="text-sm text-gray-500">Please wait...</div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8 bg-white">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
      <h2 className="text-center text-3xl font-extrabold z-500" style={{color: 'white'}}>
         Welcome To
        </h2>
        <h2 className="text-center text-3xl font-extrabold z-500" style={{color: 'white'}}>
          Hustle Fundraising
        </h2>
        {location.state?.from && (
          <p className="mt-2 text-center text-sm text-gray-600">
            Please sign in
          </p>
        )}
      </div>

      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          {error && (
            <div className="mb-4 bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded-md flex items-center justify-between">
              <span>{error}</span>
              <button 
                onClick={() => setError(null)}
                className="text-red-700 hover:text-red-800 text-sm font-medium"
              >
                Dismiss
              </button>
            </div>
          )}
<div className="flex justify-center">
          <button
            onClick={handleGoogleLogin}
            disabled={isLoading}
            className="w-3/4 flex items-center justify-center px-4 py-2 border border-solid border-2px rounded-md shadow-sm text-sm font-medium text-black bg-white-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {isLoading ? 'Connecting...' : 'Login'}
          </button>
          </div>
          <div className="mt-6">
            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full " />
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 bg-white text-gray-500">
                  Secure authentication by Google
                </span>
              </div>
            </div>
          </div>
          
          {location.state?.from && (
            <div className="mt-4 text-center text-xs text-gray-500">
              Redirecting to: {location.state.from}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Login;